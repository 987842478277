/*


event details 


*/

.events-main .event-details {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: var(--color-div-bg);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    margin: 0 8px 0 4px;
    padding: 0 0 0 0;
    overflow: hidden;
}

.events-main .event-details .event-details-back-btn {
    width: 100%;
    height: 40px;
    background-color: var(--color-div-bg);
    padding: 4px 8px 8px 10px;
    position: relative;
}

.events-main .event-details .event-details-back-btn-icon {
    font-size: 26px;
    color: var(--color-icon);
    padding: 4px 8px 8px 0;
    cursor: pointer;
}

.events-main .event-details .event-details-edit-btn {
    font-size: 16px;
    color: var(--color-view-def-txt);
    background: var(--color-view-def-bg);
    border-radius: 8px;
    /* border: 1px solid var(--color-border); */
    margin: 8px 8px 8px 8px;
    padding: 2px 14px;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.events-main .event-details .event-details-edit-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: var(--color-view-def-bg-hover);
}

.events-main .event-details .event-details-main {
    width: 100%;
    height: calc(100% - 40px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.events-main .event-details .event-details-top-cont {
    width: 100%;
    height: 200px;
    background-color: var(--color-view-bg-sec);
    /* Add the blur effect */
    filter: blur(5px);
    -webkit-filter: blur(5px);
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.events-main .event-details .event-details-image-cont {
    width: 100px;
    height: 100px;
    margin: 0 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
    background-color: var(--color-loader);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 150px;
    z-index: 2;
}

.events-main .event-details .event-details-image {
    width: 100px;
    height: 100px;
    margin: 0 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
    /* image-rendering: pixelated; */
    object-fit: cover;
    position: absolute;
    left: 20px;
    top: 150px;
    z-index: 3;
}

.events-main .event-details .event-details-image-top {
    width: 100px;
    height: 100px;
    margin: 0 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
    position: absolute;
    left: 20px;
    top: 150px;
    z-index: 4;
}

.events-main .event-details .event-details-main-cont {
    width: 100%;
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    /* background: rgb(85, 97, 189); */
    padding: 8px;
    box-sizing: border-box;
}

.events-main .event-details .event-details-main-cont .event-details-title {
    width: calc(100% - 120px);
    height: auto;
    margin: 0px 8px 8px 120px;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    color: var(--color-edit-txt);
    /* background: rgb(149, 157, 218); */
    padding: 8px;
}

.event-details .event-details-action-cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 16px 0;
    padding: 0 0 0 0;
    position: relative;
    /* border-radius: 8px;
    border: 1px solid var(--color-border);
    padding: 8px;
    background: var(--color-view-bg-sec); */
}

.event-details .event-details-action-cont .event-details-action-item {
    width: auto;
    height: auto;
    max-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 8px;
    padding: 0;
    position: relative;
}

.event-details .event-details-action-cont .event-details-action-item-btn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 4px 4px;
    position: relative;
    text-decoration: none;
    background: var(--color-view-def-bg);
    /* border: 1px solid var(--color-border); */
    border-radius: 16px;
}

.event-details .event-details-action-cont .event-details-action-item-btn:hover {
    color: var(--color-view-def-txt-hover) !important;
    background: var(--color-view-def-bg-hover);
}

.event-details .event-details-action-cont .event-details-action-icon {
    font-size: 12px;
    margin: 0 8px 0 8px;
    color: var(--color-view-def-txt);
    text-decoration: none;
}

.event-details .event-details-action-cont .event-details-action-text {
    font-size: 14px;
    margin: 0 8px 0 8px;
    color: var(--color-view-def-txt);
    text-decoration: none;
}

.event-details-date-cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 4px 0;
    padding: 0 0 0 0;
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    padding: 8px;
    /* background: var(--color-view-bg-sec); */
}

.event-details-date-cont .event-details-date-icon {
    font-size: 30px;
    color: var(--color-icon);
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 2;
    background: var(--color-div-bg);
    padding: 4px 6px;
    margin: 2px 0 0 2px;
    border-radius: 8px;
}

.event-details-date-cont .event-details-date {
    font-size: 16px;
    font-weight: 100;
    color: var(--color-edit-txt);
    padding: 4px;
    margin: 0 0 0 50px;
}

.events-main .event-details .event-details-main-cont .event-details-desc {
    width: 100%;
    margin: 0 8px 4px 0;
    font-size: 16px;
    font-weight: 100;
    white-space: pre-line;
    color: var(--color-edit-txt-sec);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    padding: 8px;
    /* background: var(--color-view-bg-sec); */
}

.event-details-counts-cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 4px 0;
    padding: 0 0 0 0;
}

.event-details-counts-item {
    width: calc((100% / 2) - 4px);
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    padding: 8px;
    margin: 0 0 0 0;
    /* background: var(--color-view-bg-sec); */
}

.event-details-counts-cont .item-0 {
    margin: 0 4px 0 0;
}

.event-details-counts-cont .item-1 {
    margin: 0 0 0 4px;
}

.event-details-counts-title {
    font-size: 20px;
    font-style: bold;
    color: var(--color-edit-txt);
    padding: 8px;
}

.event-details-counts {
    font-size: 26px;
    font-style: bold;
    font-weight: 300;
    color: var(--color-edit-txt);
    padding: 8px;
}

.events-main .event-details .event-details-main-cont .event-details-location-cont {
    width: 100%;
    /* height: auto; */
    height: 300px;
    /* min-height: 150px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background: inherit;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    padding: 8px;
    margin: 0 0 8px 0;
    /* background: var(--color-view-bg-sec); */
}

.events-main .event-details .event-details-main-cont .event-details-location-cont .location-icon {
    font-size: 30px;
    color: var(--color-icon);
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 2;
    background: var(--color-div-bg);
    padding: 4px 6px;
    margin: 2px 0 0 2px;
    border-radius: 8px;
}

.events-main .event-details .event-details-main-cont .event-details-location-cont .location-name {
    margin: 0 4px 0 16px;
    font-size: 20px;
    font-weight: 100;
    color: var(--color-edit-txt-sec);
    position: absolute;
    left: 46px;
    top: 8px;
    z-index: 2;
    /* background: var(--color-div-bg); */
    padding: 4px 6px;
    margin: 2px 0 0 0;
    border-radius: 8px;
}

.events-main .event-details .event-details-main-cont .event-details-location-cont .event-details-map-cont {
    width: 100%;
    height: 300px;
    position: relative;
    margin: 50px 0 0 0;
}

.event-details .event-details-bottom-cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.event-details .delete-event-btn {
    width: 150px;
    background-color: rgb(216, 0, 0);
    border: var(--color-border);
    letter-spacing: 2px;
    color: #ffffff;
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
}

.event-details .delete-event-btn:hover {
    background-color: rgb(151, 0, 0);
    color: #ffffff;
}

@media screen and (min-width: 751px) {}

@media screen and (max-width: 750px) {
    .events-main .event-details {
        width: calc(100% - 28px);
        height: calc(100% - 32px);
        position: absolute;
        top: 0;
        z-index: 3;
        display: none;
        margin: 16px 0 0 0;
        padding: 0 0 0 0;
    }
}

@media screen and (max-width: 720px)
/* phone screen size */

{
    .events-main .event-details {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-width: 0;
        border-radius: 0;
    }
}