/* 


form item


*/

.forms-list .form-item-cont {
    width: calc(100% - 8px);
    height: 120px;
    margin: 4px 4px 6px 4px;
    border-width: 1px;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-width: 1px;
    border-radius: 8px;
    background-color: var(--color-view-0-bg);
    cursor: pointer;
    text-decoration: none !important;
}

.forms-list .form-item-cont:hover {
    /* background-color: var(--color-view-item-background-hover); */
    background-color: var(--color-view-0-bg-hover) !important;
    color: var(--color-view-0-txt-hover) !important;
}

.forms-list .form-item-cont:hover * {
    color: var(--color-view-0-txt-hover) !important;
}

.forms-list .item-selected {
    background-color: var(--color-view-0-bg-active);
}

.forms-list .item-selected * {
    color: var(--color-view-0-txt-active) !important;
}

.forms-list .form-item-cont .form-item-image-cont {
    width: 100px;
    height: 100px;
    margin: 4px 4px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
    image-rendering: pixelated;
    /* background-color: var(--color-loader); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.forms-list .form-item-cont .form-item-image-top {
    width: 100px;
    height: 100px;
    border-width: 1px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.forms-list .form-item-cont .form-item-image {
    width: 100px;
    height: 100px;
    border-width: 1px;
    border-radius: 8px;
    image-rendering: auto;
    object-fit: cover;
}

.forms-list .form-item-cont .form-item-info {
    width: calc(100% - (100px + 16px));
    height: 100px;
    padding: 0 0 0 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.forms-list .form-item-cont .form-item-info-title {
    width: auto;
    height: auto;
    font-size: 1rem;
    font-style: bold;
    color: var(--color-edit-txt);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0 0 4px 0;
    /* word-wrap: break-word; */
    /* white-space: nowrap; */
    /* background: pink; */
}

.forms-list .form-item-cont .form-item-info-desc {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: .8rem;
    font-weight: 200;
    color: var(--color-edit-txt-sec);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* word-wrap: break-word; */
    /* white-space: nowrap; */
    /* background: blueviolet; */
}

.forms-list .form-item-cont .form-item-bottom-cont {
    width: 100%;
    height: 20px;
    overflow: hidden;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    bottom: 0;
    z-index: 1;
}

.forms-list .form-item-cont .form-item-count {
    width: auto;
    height: 100%;
    overflow: hidden;
    margin: 0 16px 8px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 100;
    color: var(--color-edit-txt-sec);
}

.forms-list .form-item-cont .form-item-count span {
    margin: 0 4px 0 16px;
    font-size: 14px;
    font-weight: 100;
    color: var(--color-edit-txt-sec);
}

@media screen and (min-width: 751px) {}

@media screen and (max-width: 750px) {}