/*


artwork details 


*/

.artworks-main .artworks-main-edit {
    width: 50%;
    height: 100%;
    position: relative;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-div-bg);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    margin: 0 8px 0 4px;
}

.artworks-main .artworks-main-list .artworks-list-cont {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.artworks-main .artworks-main-list .artworks-main-list-title {
    font-size: 16px;
    color: var(--color-edit-txt);
    margin: 8px 8px 8px 8px;
    padding: 2px 14px;
    position: absolute;
    top: 0;
    z-index: 2;
}

.artworks-main .artworks-main-edit .artwork-details-top-cont {
    width: 100%;
    height: 45px;
    background-color: var(--color-div-bg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 4px 16px 4px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.artworks-main .artworks-main-edit .artwork-details-back-btn-icon {
    font-size: 26px;
    color: var(--color-icon);
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 3;
    border-radius: 8px;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.artworks-main .artworks-main-edit .artworks-main-edit-title,
.artworks-main .artworks-main-edit .artworks-main-edit-empty-txt {
    font-size: 16px;
    color: var(--color-edit-txt);
    margin: 8px 8px 8px 8px;
    padding: 2px 14px;
}

.artworks-main .artworks-main-edit .artwork-output {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.481);
    border-radius: 8px;
    /* border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; */
}

.artworks-main .artworks-main-edit .artwork-output .error-text {
    width: 60%;
    max-width: 400px;
    background: white;
    border: var(--color-border);
    letter-spacing: 2px;
    color: #ff0000;
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.artworks-main .artworks-main-edit .artwork-output .loading-icon {
    margin: 8px;
    font-size: 50px;
    color: white;
}

.artworks-main .artworks-main-edit .artwork-details {
    width: 100%;
    height: 100%;
    margin: 45px 0 0 0;
    padding: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* background: rgb(99, 63, 63); */
    overflow-x: hidden;
    overflow-y: auto;
}

.artworks-main .artworks-main-edit .artwork-details-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: relative;
}

.artworks-main .artworks-main-edit .artwork-details .artwork-input-cont {
    width: calc(100% - 8px);
    max-width: 350px;
    height: auto;
    /* min-height: 100px; */
    margin: 4px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-width: 1px;
    border-radius: 8px;
    border-color: var(--color-border);
    background-color: var(--color-view-bg-sec);
    text-decoration: none !important;
    position: relative;
}

.artworks-main .artworks-main-edit .artwork-details .artwork-input-dropzone {
    border-radius: 8px;
    /* border: 4px dashed var(--color-view-def-bg); */
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
    color: var(--color-edit-txt);
    font-size: 14px;
    font-weight: 200;
}

.artworks-main .artworks-main-edit .artwork-details ul {
    list-style: none;
    text-align: center;
    padding: 16px;
    color: var(--color-edit-txt);
    font-size: 12px;
    font-weight: 100;
}

.artworks-main .artworks-main-edit .artwork-image-cont {
    width: auto;
    height: auto;
    max-width: calc(100% - 16px);
    max-height: 600px;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    border-radius: 8px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.artworks-main .artworks-main-edit .artwork-image-cont:hover>.artwork-image-icon {
    display: flex;
}

.artworks-main .artworks-main-edit .artwork-image-cont:hover>.artwork-image-top {
    display: flex;
    background: rgba(255, 255, 255, 0.145);
}

.artworks-main .artworks-main-edit .artwork-image-cont .artwork-image-icon {
    margin: 8px;
    font-size: 20px;
    color: black;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    display: none;
}

.artworks-main .artworks-main-edit .artwork-image-cont .artwork-image-top {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.artworks-main .artworks-main-edit .artwork-image-cont .artwork-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 600px;
    border: var(--color-border);
    border-radius: 8px;
    image-rendering: pixelated;
    object-fit: cover;
}

.artworks-main .artworks-main-edit .artwork-details .artwork-field .artwork-field-cancel-btn:hover {
    background: rgb(146, 26, 26);
}

.artworks-main .artworks-main-edit .artwork-details .artwork-field .artwork-field-inputs {
    margin: 30px 0 0 0;
}

.artworks-main .artworks-main-edit .artwork-details .artwork-field input,
.artworks-main .artworks-main-edit .artwork-details .artwork-field select {
    width: 100%;
    height: auto;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-edit-txt);
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
}


/* .artworks-main .artworks-main-edit .artwork-details .artwork-field .field-selector-cont {
    width: 100%;
    height: auto;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-edit-txt);
    padding: 4px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} */


/* .artworks-main .artworks-main-edit .artwork-details .artwork-field select {
    width: 150px;
    height: auto;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-edit-txt);
    padding: 8px 4px;
    margin: 0 8px 0 8px;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
} */

.artworks-main .artworks-main-edit .artwork-details .artwork-details-btns-cont {
    width: 100%;
    height: 60px;
    margin: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.artworks-main .artworks-main-edit .artwork-details .artwork-details-btns-cont .artwork-details-ok-btn,
.artworks-main .artworks-main-edit .artwork-details .artwork-details-btns-cont .artwork-details-add-btn {
    width: auto;
    color: var(--color-view-def-txt);
    background: var(--color-view-def-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    padding: 12px 20px;
    margin: 8px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
}

.artworks-main .artworks-main-edit .artwork-details .artwork-details-btns-cont .artwork-details-ok-btn:hover,
.artworks-main .artworks-main-edit .artwork-details .artwork-details-btns-cont .artwork-details-add-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: var(--color-view-def-bg-hover);
}

.artworks-main .artworks-main-edit .artwork-cont .error-text {
    width: 100%;
    background: white;
    border: #8b8b8b;
    letter-spacing: 2px;
    color: #414141;
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 12px;
}

@media screen and (min-width: 751px) {
    .artworks-main .artworks-main-edit {
        display: flex !important;
    }
}

@media screen and (max-width: 750px) {
    .artworks-main .artworks-main-edit {
        width: calc(100% - 28px);
        height: calc(100% - 32px);
        position: absolute;
        top: 0;
        z-index: 3;
        display: none;
        margin: 16px 0 0 0;
        padding: 0 0 0 0;
        border: 1px solid var(--color-border);
    }
}

@media screen and (max-width: 720px)
/* phone screen size */

{
    .artworks-main .artworks-main-edit {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-width: 0;
        border-radius: 0;
    }
    .artworks-main .artworks-main-edit .artwork-output {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-width: 0;
        border-radius: 0;
    }
}