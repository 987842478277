.signup-main {
    width: 100%;
    height: 100%;
    background-color: var(--color-main-screen-bg);
    text-align: center;
    /* color: white; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.signup-main a {
    color: var(--color-txt-pri);
}

.main-header-full {
    width: 100% !important;
}

.signup-main .form-container {
    width: 90%;
    height: auto;
    max-width: 400px;
    min-width: 200px;
    padding: 16px 10px 16px 10px;
    display: inline-block;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-div-bg);
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-border);
    margin: 58px 0 32px 0;
}

.signup-main .form-container .signup-title {
    font-size: 20px;
    color: var(--color-edit-txt);
    margin: 0 8px 8px 8px;
    /* position: absolute;
    top: 0;
    z-index: 2; */
}

.signup-main .form-container span {
    letter-spacing: 2px;
    color: var(--color-edit-txt-sec);
    padding: 12px 20px;
    margin: 4px 0;
    cursor: pointer;
}

.signup-main .form-container .input {
    width: 90%;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-edit-txt-sec);
    /* width: 50%; */
    padding: 12px 20px;
    /* margin: 8px 0; */
    box-sizing: border-box;
    border-radius: 8px;
    margin: 8px 0 8px 0;
}

.signup-main .form-container .signup-btn {
    width: 90%;
    color: var(--color-view-def-txt);
    background: var(--color-view-def-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    padding: 12px 20px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
    margin: 8px 0 8px 0;
}

.signup-main .form-container .signup-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: var(--color-view-def-bg-hover);
}

.signup-main .form-container .loading-icon {
    /* width: 300px; */
    color: var(--color-icon);
    font-size: 34px;
    font-weight: bold;
    padding: 4px;
    margin: 12px 0 12px 0;
    position: relative;
    left: 0;
    right: 0;
    box-sizing: border-box;
}

.signup-main .form-container .create-account-lay {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px;
    margin: 8px 0 8px 0;
    border: 1px solid var(--color-border);
    border-radius: 8px;
}

.signup-main .form-container .create-account-lay * {
    padding: 2px;
    font-size: 14px;
    font-weight: normal;
}

.signup-main .form-container .message-box {
    width: 90%;
    color: rgb(255, 42, 42);
    font-size: 12px;
    font-weight: bold;
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 8px;
    margin: 8px 0 8px 0;
}

.signup-main .footer-cont {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin: 8px 0 8px 0;
}

.signup-main .footer-cont .footer-seg {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.signup-main .footer-cont .footer-seg * {
    font-size: 11px;
    /* color: var(--color-edit-txt-sec); */
    padding: 0;
    margin: 2px 4px 2px 4px;
}

@media screen and (max-width: 720px)
/* phone screen size */

{
    body,
    .signup-main {
        background-color: var(--color-div-bg) !important;
    }
    .signup-main .form-container {
        width: 100%;
        height: 100%;
        margin: 52px 0 0 0;
        padding: 0;
        border-width: 0;
        border-radius: 0;
    }
}