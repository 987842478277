/* 


new event


*/

.events-main .events-main-new {
    width: 50%;
    height: 100%;
    position: relative;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-div-bg);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    margin: 0 8px 0 4px;
    padding: 40px 0 0 0;
}

.events-main .events-main-new .events-main-create-title {
    font-size: 16px;
    color: var(--color-edit-txt);
    margin: 8px 8px 8px 8px;
    padding: 2px 14px;
    position: absolute;
    top: 0;
    z-index: 1;
}

.events-main .events-main-new .events-main-create-cancel-btn {
    font-size: 16px;
    color: var(--color-view-def-txt);
    background: var(--color-view-def-bg);
    border-radius: 8px;
    /* border: 1px solid var(--color-border); */
    margin: 8px 8px 8px 8px;
    padding: 2px 14px;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.events-main .events-main-new .events-main-create-cancel-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: var(--color-view-def-bg-hover);
}

.events-main .events-main-new .event-form-cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 8px 0 8px;
}

.events-main .events-main-new .event-form-output {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.481);
    border-radius: 8px;
    /* border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; */
}

.events-main .events-main-new .event-form-output .error-text {
    width: 60%;
    max-width: 400px;
    background: white;
    border: var(--color-border);
    letter-spacing: 2px;
    color: #ff0000;
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.events-main .events-main-new .event-form-output .loading-icon {
    margin: 8px;
    font-size: 50px;
    color: white;
}

.events-main .events-main-new .event-form-cont .input,
.events-main .events-main-new .event-form-cont .event-location-btn,
.events-main .events-main-new .event-form-cont .event-image-btn {
    width: 100%;
    height: auto;
    max-width: 350px;
    min-height: 50px;
    max-height: 250px;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-edit-txt-sec);
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    resize: vertical;
}

.events-main .events-main-new .event-form-cont .event-location-btn,
.events-main .events-main-new .event-form-cont .event-image-btn {
    cursor: pointer;
}

.events-main .events-main-new .event-form-cont .event-location-map-modal {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    z-index: 98 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.481);
    display: none;
}

.events-main .events-main-new .event-form-cont .event-location-map-cont {
    width: 60%;
    height: 60%;
    min-width: 300px;
    max-width: 600px;
    min-height: 500px;
    max-height: 600px;
    background: var(--color-div-bg);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    color: var(--color-edit-txt-sec);
    box-sizing: border-box;
    position: fixed;
    z-index: 99 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

.events-main .events-main-new .event-form-cont .event-date-title {
    max-width: 350px;
    letter-spacing: 2px;
    color: var(--color-edit-txt-sec);
    text-align: center;
    /* margin: 8px 0 8px 0; */
}

.events-main .events-main-new .event-form-cont .event-date-cont {
    width: 100%;
    max-width: 350px;
    /* background: #070707; */
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-edit-txt-sec);
    /* padding: 12px 20px; */
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    /* width: 60%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px;
    margin: 4px 0 4px 0;
    box-sizing: border-box; */
}

.events-main .events-main-new .event-form-cont .event-image-cont {
    width: 150px;
    height: 150px;
    max-height: 250px;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    border-radius: 8px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.events-main .events-main-new .event-form-cont .event-image-cont:hover>.event-image-icon {
    display: flex;
}

.events-main .events-main-new .event-form-cont .event-image-cont:hover>.event-image-top {
    display: flex;
    background: rgba(255, 255, 255, 0.522);
}

.events-main .events-main-new .event-form-cont .event-image-cont .event-image-icon {
    margin: 8px;
    font-size: 20px;
    color: black;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    display: none;
}

.events-main .events-main-new .event-form-cont .event-image-cont .event-image-top {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.events-main .events-main-new .event-form-cont .event-image-cont .event-image {
    width: 150px;
    height: 150px;
    border: var(--color-border);
    border-radius: 8px;
    image-rendering: pixelated;
    object-fit: cover;
}

.events-main .events-main-new .event-form-cont .create-event-btn {
    width: 100%;
    max-width: 350px;
    /* background: #070707; */
    background-color: var(--color-view-def-bg) !important;
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-view-def-txt);
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 8px;
}

.events-main .events-main-new .event-form-cont .create-event-btn:hover,
.events-main .events-main-new .event-form-cont .event-location-btn:hover,
.events-main .events-main-new .event-form-cont .event-image-btn:hover {
    background-color: var(--color-view-def-bg-hover) !important;
    color: var(--color-view-def-txt-hover) !important;
}

@media screen and (min-width: 751px) {
    .events-main {
        width: 100%;
        overflow-y: auto;
    }
    .events-main .events-main-first-lay {
        height: calc(100vh - 50px);
    }
}

@media screen and (max-width: 750px) {
    .events-main .events-main-new {
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
    }
    .events-main .events-main-new-wm {
        width: calc(100% - 28px);
        height: calc(100% - 32px);
        position: absolute;
        top: 0;
        z-index: 3;
        display: none;
        margin: 16px 0 0 0;
        padding: 40px 0 0 0;
        border: 1px solid var(--color-border);
    }
    .events-main .events-main-new .events-main-create-title {
        display: none
    }
    .events-main .events-main-new .events-main-create-title-wm {
        display: flex;
    }
    .events-main .events-main-new .events-main-create-cancel-btn {
        display: none;
    }
    .events-main .events-main-new .events-main-create-cancel-btn-wm {
        display: flex;
    }
    .events-main .events-main-new .event-form-cont {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 720px)
/* phone screen size */

{
    .events-main .events-main-new-wm {
        width: 100%;
        height: 100%;
        margin: 0;
        /* padding: 0; */
        border-width: 0;
        border-radius: 0;
    }
    .events-main .events-main-new .event-form-output {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-width: 0;
        border-radius: 0;
    }
}