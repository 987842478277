.loading-cont {
    width: 100%;
    height: inherit;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: inherit;
    padding: inherit;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}


/* .loading-cont .event-loading-item {
    width: calc(100% - 16px);
    height: calc(48px - 8px);
    margin: 4px 8px 4px 8px;
    box-sizing: border-box;
    border-width: 1px;
    border-radius: 8px;
    border-style: solid;
    border-color: var(--color-border);
} */

.loading-cont .event-loading-item {
    width: 100%;
    height: 100px;
    margin: 4px 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
}

.loading-cont .event-loading-item .event-info {
    width: calc(100% - 100px);
    height: 100px;
    margin: 0 0 0 0;
    padding: 10px 0 10px 10px;
    display: flex;
    flex-direction: column;
}

.loading-cont .event-loading-item .event-image {
    width: 100px;
    height: 100px;
    margin: 4px 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
}

.loading-cont .event-loading-item .event-info-title {
    width: 60%;
    height: 20px;
    margin: 4px 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
}

.loading-cont .event-loading-item .event-info-desc {
    width: 40%;
    height: 20px;
    margin: 4px 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
}


/* attendee */

.loading-cont .attendee-loading-item {
    width: 100%;
    height: 100px;
    margin: 0 8px 0 8px;
    border-width: 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
}

.loading-cont .attendee-loading-item .attendee-info {
    width: calc(100% - 100px);
    height: 100px;
    margin: 0 0 0 0;
    padding: 10px 0 10px 10px;
    display: flex;
    flex-direction: column;
}

.loading-cont .attendee-loading-item .attendee-image {
    width: 70px;
    height: 70px;
    margin: 4px 8px 4px 8px;
    border-width: 1px;
    border-radius: 50px;
}

.loading-cont .attendee-loading-item .attendee-info-title {
    width: 60%;
    height: 20px;
    margin: 4px 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
}

.loading-cont .attendee-loading-item .attendee-info-desc {
    width: 40%;
    height: 20px;
    margin: 4px 8px 4px 8px;
    border-width: 1px;
    border-radius: 8px;
}