.main-header {
    /* width: calc(100% - 220px); */
    width: inherit;
    height: 50px;
    background-color: var(--color-toolbar-bg);
    /* background: rgb(62, 132, 170); */
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 3px 2px -1px var(--color-shadow); */
    border-bottom: 1px solid var(--color-border);
}

.main-header-full {
    width: 100%;
    height: 50px;
    background-color: var(--color-toolbar-bg);
    /* background: rgb(62, 132, 170); */
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 3px 2px -1px var(--color-shadow); */
}

.main-header .menu-btn {
    position: absolute;
    left: 0;
    margin: 0 0 0 10px;
    cursor: pointer;
    display: none;
    color: var(--color-icon);
    font-size: 26px;
}

.main-header .app-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 6px;
    word-spacing: 2px;
    color: var(--color-txt-pri);
    padding: 0px;
    margin: 0 8px 0 8px;
    justify-content: center;
    text-align: center;
}

.main-header .user-lay {
    width: auto;
    height: 68%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    right: 0;
    margin: 0 90px 0 0;
    background-color: var(--color-main-screen-bg);
    border-radius: 50px;
    text-decoration: none !important;
}

.main-header .user-lay .user-name {
    font-size: 13px;
    color: var(--color-edit-txt-sec);
    margin: 0 8px 0 16px;
}

.main-header .user-lay .user-icon {
    font-size: 20px;
    color: var(--color-edit-txt-sec);
    margin: 0 8px 0 16px;
}

.main-header .pallete-container {
    width: 70px;
    display: flex;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    right: 0;
    margin: 0 10px 0 0;
}

.main-header .pallete-container .switch {
    display: inline-block;
    width: 60px;
    height: 34px;
    position: relative;
}

.main-header .pallete-container .toggle-thumb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-main-screen-bg);
    border-radius: 50px;
    cursor: pointer;
    /* overflow: hidden; */
}

.main-header .pallete-container .toggle-thumb:before {
    content: "";
    height: 26px;
    width: 26px;
    position: absolute;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: var(--color-pallete-toggle);
    transition: .4s all ease;
}

.main-header .pallete-container .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.main-header .pallete-container .checkbox:checked+.toggle-thumb:before {
    transform: translateX(26px);
}


/* 
side-nav
 */

.side-nav {
    width: 220px;
    height: 100vh;
    background-color: var(--color-side-nav);
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-right: 1px solid var(--color-border);
}

.side-nav-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 1;
    /* background-color: var(--color-side-nav); */
    /* background-color: pink; */
    border-bottom: 1px solid var(--color-border);
}

.side-nav-header .home-btn {
    width: 96%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 4px 0 4px;
    padding: 0 16px 0 8px;
    /* background-color: var(--color-view-bg-active); */
    /* background-color: var(--color-view-bg-sec); */
    /* border: 1px solid var(--color-border); */
    /* border-radius: 8px; */
}

.side-nav-header .home-btn .header-logo-img {
    width: 30px;
    height: 30px;
    margin: 0 4px 0 4px;
    image-rendering: pixelated;
}

.side-nav-header .home-btn .header-text-cont {
    width: calc(100% - (30px + 8px));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.side-nav-header .home-btn span {
    width: 100%;
    font-size: 13px;
    font-weight: 1000;
    line-height: auto;
    letter-spacing: 3px;
    color: var(--color-txt-pri) !important;
    margin: 0 8px 0 32px;
    /* background-color: pink; */
}

.side-nav a,
.side-nav a:hover,
.side-nav a:focus,
.side-nav a:active {
    text-decoration: none !important;
    /* color: inherit; */
}


/* .home-btn .home-icon {
    font-size: 20px;
    color: var(--color-icon);
    margin: 0 8px 0 16px;
    position: absolute;
    left: 0;
} */

.side-nav-btns-cont {
    width: 100%;
    height: calc(100% - 50px);
    background-color: var(--color-side-nav);
    position: relative;
    /* display: flex; */
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 50px 0 50px 0;
}

.logout-btn-cont {
    width: 220px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    background-color: var(--color-side-nav);
    border-right: 1px solid var(--color-border);
}

.side-nav-large-lay {
    width: 96%;
    /* height: 190px; */
    height: 2px;
    /* background-color: var(--color-view-bg-sec); */
    border-radius: 8px;
    /* border: 1px solid var(--color-border); */
    /* border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; */
    margin: 0 4px 4px 4px;
    padding: 8px 4px 8px 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.side-nav-btn {
    width: 96%;
    height: 40px !important;
    background-color: var(--color-view-bg);
    border-radius: 8px;
    /* border: 1px solid var(--color-view-bg); */
    /* border: 1px solid var(--color-border); */
    /* border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; */
    margin: 4px 0 4px 4px;
    padding: 8px 4px 8px 4px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.side-nav-btn i {
    font-size: 20px;
    color: var(--color-view-txt);
    margin: 0 8px 0 16px;
    position: absolute;
    left: 0;
}

.side-nav-btn span {
    font-size: 1rem;
    /* font-weight: 300; */
    /* line-height: 1.5; */
    /* font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif; */
    font-family: 'Lato', sans-serif;
    letter-spacing: 2px;
    color: var(--color-view-txt);
    margin: 0 8px 0 16px;
    position: absolute;
    left: 40px;
}

.btn-active {
    background-color: var(--color-view-bg-active);
}

.btn-active i,
.btn-active span {
    color: var(--color-view-txt-active);
}

.side-nav-btn:hover {
    background-color: var(--color-view-bg-hover);
    color: var(--color-view-txt-hover);
}

.side-nav-btn:hover i,
.side-nav-btn:hover span {
    color: var(--color-view-txt-hover);
}

.side-nav .footer-cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px 0 16px;
    margin: 24px 0 8px 0;
    /* background-color: rgb(60, 60, 60); */
}

.side-nav .footer-cont a {
    color: var(--color-txt-pri);
    text-decoration: none !important;
}

.side-nav .footer-cont span {
    color: var(--color-edit-txt-sec);
}

.side-nav .footer-cont a:hover {
    text-decoration: underline !important;
}

.side-nav .footer-cont .footer-seg {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
}

.side-nav .footer-cont .footer-seg * {
    font-size: 13px;
    padding: 0;
    margin: 2px 4px 2px 4px;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 900px) {
    .main-header {
        /* width: 100%; */
        justify-content: flex-start;
        align-items: left;
    }
    .main-header .menu-btn {
        display: flex;
    }
    .main-header .app-title {
        font-size: 16px;
        margin: 0 10px 0 45px;
    }
    .main-header .user-lay .user-name {
        display: none;
    }
    .main-header .user-lay .user-icon {
        font-size: 20px;
        margin: 0 16px 0 16px;
    }
}

@media screen and (max-width: 1000px) {
    .main-header .menu-btn {
        display: flex;
    }
}