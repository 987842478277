.landing-main {
    width: 100%;
    background: var(--color-main-screen-bg);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: rgb(225, 97, 97); */
}

.landing-header {
    width: 100%;
    /* width: calc(100% - 250px); */
    height: 50px;
    background-color: var(--color-toolbar-bg);
    /* background: rgb(62, 132, 170); */
    position: fixed;
    z-index: 6;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 3px 2px -1px var(--color-shadow); */
}

.landing-header .menu-btn {
    position: absolute;
    left: 0;
    margin: 0 0 0 10px;
    cursor: pointer;
    display: none;
    color: var(--color-icon);
}

.landing-header .app-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 6px;
    word-spacing: 2px;
    color: var(--color-txt-pri);
    padding: 0px;
    margin: 0 8px 0 8px;
    justify-content: center;
    text-align: center;
}

.landing-header .user-lay {
    width: auto;
    height: 68%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    right: 0;
    margin: 0 90px 0 0;
    background-color: var(--color-main-screen-bg);
    border-radius: 50px;
}

.landing-header .user-lay .user-name {
    font-size: 13px;
    color: var(--color-edit-txt);
    margin: 0 8px 0 16px;
}

.landing-header .user-lay .user-icon {
    font-size: 20px;
    color: var(--color-icon);
    margin: 0 8px 0 16px;
}

.landing-header .pallete-container {
    width: 70px;
    display: flex;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    right: 0;
    margin: 0 10px 0 0;
}

.landing-header .pallete-container .switch {
    display: inline-block;
    width: 60px;
    height: 34px;
    position: relative;
}

.landing-header .pallete-container .toggle-thumb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-main-screen-bg);
    border-radius: 50px;
    cursor: pointer;
    /* overflow: hidden; */
}

.landing-header .pallete-container .toggle-thumb:before {
    content: "";
    height: 26px;
    width: 26px;
    position: absolute;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: var(--color-pallete-toggle);
    transition: .4s all ease;
}

.landing-header .pallete-container .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.landing-header .pallete-container .checkbox:checked+.toggle-thumb:before {
    transform: translateX(26px);
}

.first-lay {
    width: 90%;
    max-width: 1600px;
    height: 500px;
    margin: 70px 0px 8px 0px;
    position: relative;
    display: flex;
    flex-direction: row;
}

.first-lay .left-cont {
    width: 40%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
}

.first-lay .left-cont .first-title {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--color-txt-pri);
    padding: 40;
    margin: 0 0 0 0;
}

.first-lay .left-cont .open-devices-btn {
    font-size: 15px;
    font-weight: bold;
    padding: 8px 16px 8px 16px;
    margin: 24px 16px 16px 16px;
    border-radius: 16px;
    color: var(--color-view-def-txt);
    background: var(--color-txt-pri);
    cursor: pointer;
    box-shadow: 0px 0px 4px 2px var(--color-shadow);
}

.first-lay .left-cont .open-devices-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: var(--color-view-def-bg-hover);
}

.first-lay .right-cont {
    width: 70%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.first-lay .right-cont video {
    width: 90%;
    height: 80%;
    image-rendering: pixelated;
    background: var(--color-main-screen-bg);
}

@media screen and (max-width: 900px) {
    .landing-header {
        justify-content: flex-start;
        align-items: left;
    }
    .landing-header .app-title {
        font-size: 16px;
        margin: 0 10px 0 45px;
    }
    .landing-header .user-lay .user-name {
        display: none;
    }
    .landing-header .user-lay .user-icon {
        font-size: 20px;
        margin: 0 16px 0 16px;
    }
    .first-lay {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .first-lay .left-cont {
        width: 100%;
        height: auto;
    }
    .first-lay .left-cont .first-title {
        font-size: 35px;
        text-align: center;
    }
}