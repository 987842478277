.artworks-main {
    width: 100%;
    height: auto;
    max-width: 1200px;
    background: inherit;
    position: relative;
    /* display: flex; */
    flex-direction: column;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.artworks-main .artworks-main-first-lay {
    width: 100%;
    /* height: calc(100vh - 50px); */
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    box-sizing: border-box;
    padding: 16px;
}

.artworks-main .artworks-main-list {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-div-bg);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    margin: 0 4px 0 8px;
    padding: 40px 0 0 0;
    /* overflow: hidden; */
}

.artworks-main .artworks-main-list .artworks-list {
    width: inherit;
    height: inherit;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    overflow-x: hidden;
}

.artworks-main .artworks-main-list .artworks-main-empty-cont {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.artworks-main .artworks-main-list .artworks-main-empty-top {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.artworks-main .artworks-main-list .artworks-main-empty-cont .artworks-main-empty-img {
    width: 90%;
    height: 80%;
    min-width: 100px;
    min-height: 300px;
    max-width: 400px;
    max-height: 500px;
    margin: 0 0 0 0;
    padding: 50px;
    image-rendering: auto;
    object-fit: contain;
}

.artworks-main .artworks-main-list .artworks-main-empty-cont .artworks-main-empty-txt {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-edit-txt);
    margin: 0 0 0 0;
}

@media screen and (min-width: 751px) {
    .artworks-main {
        width: 100%;
        overflow-y: auto;
    }
    .artworks-main .artworks-main-first-lay {
        height: calc(100vh - 50px);
    }
}

@media screen and (max-width: 750px) {
    .artworks-main {
        width: 100%;
        height: calc(100% - 50px);
    }
    .artworks-main .artworks-main-first-lay {
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
    }
    .artworks-main .artworks-main-list {
        width: 100%;
        height: inherit;
        box-sizing: border-box;
    }
    .artworks-main .artworks-main-list .artworks-main-add-btn {
        display: flex;
    }
}

@media screen and (max-width: 720px)
/* phone screen size */

{
    .artworks-main .artworks-main-first-lay {
        margin: 0;
        padding: 0;
        border: none;
    }
    .artworks-main .artworks-main-list {
        width: 100%;
        height: 100%;
        margin: 0;
        /* padding: 0; */
        border-width: 0;
        border-radius: 0;
    }
}