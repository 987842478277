 .map-cont {
     width: 100%;
     height: 100%;
     border-width: 1px;
     border-radius: 8px;
     border-color: var(--color-border);
     position: relative;
     /* position: fixed;
     z-index: 99;
     height: 100vh; */
     background-color: var(--color-div-bg);
 }
 
 .map-cont * {
     border-color: inherit;
     border-width: 1px;
     border-radius: 8px;
     border-color: var(--color-border);
     /* border-style: solid !important; */
 }