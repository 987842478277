.registration-main-lay {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: var(--color-main-screen-bg); */
    image-rendering: pixelated;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow-y: auto;
}

.reg-main-bg {
    width: 110vw;
    height: 110vh;
    /* background: var(--color-main-screen-bg); */
    filter: blur(10px);
    -webkit-filter: blur(10px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    image-rendering: pixelated;
    padding: 0;
    margin: -5vh 5vw -5vh -5vw;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.registration-main-lay-back {
    width: 100%;
    height: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    border: 1px solid var(--color-border);
    border-top: 0;
    background: var(--color-div-bg);
}

.registration-main-lay .loading-cont {
    width: 100vw;
    height: 100vh;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-main-screen-bg);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);
}

.registration-main-lay .loading-cont .loading-icon {
    font-size: 2.5rem;
    color: var(--color-icon);
}

.registration-main-lay .registration-main-content {
    width: 100%;
    height: auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    border-radius: 0;
    border: 1px solid var(--color-border);
    border-top: 0;
    background: var(--color-div-bg);
    /* overflow: auto; */
}

.registration-main-lay .error-general-cont {
    width: 100vw;
    height: 100vh;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-main-screen-bg);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);
}

.registration-main-lay .error-general-cont .error-general-icon {
    font-size: 2.5rem;
    color: var(--color-icon);
    margin: 8px;
}

.registration-main-lay .error-general-cont .error-general-text {
    font-size: 16px;
    color: var(--color-edit-txt);
    margin: 20px 0 20px 0;
}

.registration-main-lay .registration-main-top-lay {
    width: 100%;
    height: 250px;
    background-color: var(--color-view-bg-sec);
    /* Add the blur effect */
    /* filter: blur(.5px); */
    /* -webkit-filter: blur(.5px); */
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    image-rendering: pixelated;
}

.registration-main-lay .registration-info {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    /* background: rgb(85, 97, 189); */
    padding: 0;
    box-sizing: border-box;
}

.registration-main-lay .registration-info .registration-title {
    width: 100%;
    height: auto;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    color: var(--color-edit-txt);
    /* background: rgb(149, 157, 218); */
    padding: 8px;
}

.registration-main-lay .registration-info .registration-date-lay {
    width: 100%;
    height: auto;
    margin: 0;
    font-size: 14px;
    /* background: rgb(169, 169, 169); */
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
}

.registration-main-lay .registration-info .registration-date-lay .date-icon {
    font-size: 18px;
    color: var(--color-icon);
}

.registration-main-lay .registration-info .registration-date-lay .registration-date {
    width: 100%;
    height: auto;
    margin: 0;
    font-size: 14px;
    font-style: italic;
    color: var(--color-edit-txt);
    padding: 8px;
    margin: 0 0 0 8px;
}

.registration-main-lay .registration-info .registration-about {
    width: 100%;
    height: auto;
    margin: 0;
    font-size: 14px;
    font-style: normal;
    white-space: pre-line;
    color: var(--color-edit-txt);
    /* background: rgb(255, 189, 189); */
    padding: 8px;
    margin: 0 0 0 0;
    /* border-top: 1px solid var(--color-border); */
}

.registration-main-lay .registration-info .registration-info-bottom {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.registration-main-lay .registration-info .registration-btn {
    font-size: 16px;
    color: var(--color-view-def-txt);
    background: var(--color-view-def-bg);
    border-radius: 8px;
    /* border: 1px solid var(--color-border); */
    /* margin: 56px 16px 56px 16px; */
    margin: 16px;
    padding: 4px 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.registration-main-lay .registration-info .registration-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: var(--color-view-def-bg-hover);
}

.registration-main-lay .registration-info .registration-error {
    font-size: 16px;
    color: #fff;
    white-space: pre-line;
    background: rgb(255, 0, 0);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    margin: 56px 16px 56px 16px;
    padding: 4px 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.reg-form-cont {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    /* background: rgb(85, 97, 189); */
    padding: 8px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-border);
    /* background: var(--color-view-bg-sec); */
}

.reg-form-cont .reg-form-header {
    width: 100%;
    height: 40px;
    margin: 0;
    /* background: rgb(149, 157, 218); */
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.reg-form-cont .reg-form-header .reg-form-title {
    width: auto;
    height: auto;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: var(--color-edit-txt);
    /* background: rgb(149, 157, 218); */
    padding: 2px 8px;
}

.reg-form-cont .reg-form-body {
    width: 100%;
    height: auto;
    margin: 8px 0 8px 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.reg-form-cont .reg-form-body .field-item {
    width: calc(100% - 8px);
    max-width: 450px;
    height: auto;
    margin: 4px;
    padding: 4px;
    border-width: 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-width: 1px;
    border-radius: 8px;
    /* background-color: var(--color-view-bg-sec); */
    text-decoration: none !important;
    position: relative;
}

.reg-form-cont .reg-form-body .field-item .field-label {
    font-size: 16px;
    color: var(--color-edit-txt);
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
    border-radius: 8px;
    padding: 0;
    margin: 0;
}

.reg-form-cont .reg-form-body .field-item .field-inputs {
    margin: 30px 0 0 0;
    width: 100%;
}

.reg-form-cont .reg-form-body .field-item .field-inputs .field-input {
    width: 100%;
    height: auto;
    background: var(--color-main-screen-bg);
    border: var(--color-border);
    letter-spacing: 2px;
    color: var(--color-edit-txt);
    padding: 12px 20px;
    margin: 8px 0 8px 0;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
}

.reg-form-cont .reg-form-footer {
    width: 100%;
    height: 40px;
    margin: 20px 0 50px 0;
    /* background: rgb(149, 157, 218); */
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.reg-form-cont .reg-form-footer .reg-form-close-btn {
    width: auto;
    height: auto;
    margin: 8px;
    padding: 4px 8px;
    color: var(--color-view-def-txt);
    background: rgb(255, 51, 51);
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
}

.reg-form-cont .reg-form-footer .reg-form-close-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: rgb(146, 26, 26);
}

.reg-form-cont .reg-form-footer .reg-form-submit-btn {
    width: auto;
    height: auto;
    margin: 8px;
    padding: 4px 8px;
    color: var(--color-view-def-txt);
    background: var(--color-view-def-bg);
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
}

.reg-form-cont .reg-form-footer .reg-form-submit-btn:hover {
    color: var(--color-view-def-txt-hover);
    background: var(--color-view-def-bg-hover);
}

.registration-main-lay .footer-cont {
    width: 100%;
    height: auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding: 54px 0 20px 0;
    background: var(--color-div-bg);
}

.registration-main-lay .footer-cont a {
    color: var(--color-txt-pri);
    text-decoration: none !important;
}

.registration-main-lay .footer-cont span {
    color: var(--color-edit-txt-sec);
}

.registration-main-lay .footer-cont a:hover {
    text-decoration: underline !important;
}

.registration-main-lay .footer-cont .footer-seg {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.registration-main-lay .footer-cont .footer-seg * {
    font-size: 11px;
    padding: 0;
    margin: 2px 4px 2px 4px;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 750px) {
    .reg-main-bg {
        display: none;
    }
    .registration-main-lay .registration-main-content {
        width: 100%;
        position: relative;
        border: none;
        padding: 0;
    }
}