.main {
    width: 100%;
    height: auto;
    background: var(--color--main-screen-bg);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.main-wrapper {
    width: calc(100% - 220px);
    height: 100%;
    /* height: calc(100% - 50px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    overflow: hidden;
    margin: 50px 0 0 220px;
    background: inherit;
    z-index: 4;
    background: var(--color-main-screen-bg);
}

.main-wrapper-wm {
    width: calc(100vw - 220px);
    height: 100%;
    /* height: calc(100% - 50px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 50px 0 0 220px;
    background: inherit;
    z-index: 4;
    background: var(--color-main-screen-bg);
    animation-name: open-nav-anim;
    animation-duration: .2s;
}

.main-nav-modal {
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0 0 0 220px;
    position: fixed !important;
    z-index: 1000 !important;
    background: rgba(0, 0, 0, 0.481);
    display: none;
    animation-name: open-nav-anim;
    animation-duration: .2s;
}


/* create animation-name for margin-left from 0px to 220px */

@keyframes open-nav-anim {
    0% {
        margin: 50px 0 0 0px;
    }
    100% {
        margin: 50px 0 0 220px;
    }
}

@media only screen and (max-width: 1000px) {
    .main-wrapper {
        width: 100%;
        margin: 50px 0 0 0;
    }
    .main-nav-modal {
        width: 100%;
        height: 100%;
    }
}