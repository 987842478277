.dashboard-main {
    width: 100%;
    height: auto;
    max-width: 1000px;
    background: inherit;
    position: relative;
    /* display: flex; */
    flex-direction: column;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.dashboard-main .dash-main-first-lay {
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    box-sizing: border-box;
    padding: 4px;
}

.dashboard-main .dash-main-first-lay .count-lay {
    width: 35%;
    height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-div-bg);
    border-radius: 8px;
    border: 1px solid var(--color-border);
    margin: 0 8px 0 8px;
}

.dashboard-main .dash-main-first-lay .count-text {
    font-size: 50px;
    font-style: bold;
    color: var(--color-edit-txt);
    margin: 0 8px 0 8px;
    position: relative;
}

.dashboard-main .dash-main-first-lay .count-info {
    font-size: 24px;
    font-style: bold;
    color: var(--color-edit-txt);
    margin: 0 8px 0 8px;
    position: relative;
}

.dashboard-main .dash-main-second-lay {
    width: 100%;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    box-sizing: border-box;
    padding: 4px;
}

.dashboard-main .dash-main-second-lay .second-lay {
    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    background-color: var(--color-div-bg);
    border-radius: 8px;
    border: 1px solid var(--color-border);
}

.dashboard-main .dash-main-second-lay .dash-main-latest-lay {
    width: 60%;
    height: 99%;
    margin: 0 8px 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-main .dash-main-second-lay .dash-main-latest-lay .dash-main-latest-list-cont {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.dashboard-main .dash-main-second-lay .dash-main-map-lay {
    width: 40%;
    height: 99%;
    margin: 0 8px 0 8px;
}

.dashboard-main .dash-main-second-lay .dash-main-latest-lay .latest-title {
    font-size: 16px;
    color: var(--color-edit-txt);
    margin: 8px 8px 8px 8px;
    padding: 2px 14px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .dashboard-main {
        width: 100%;
    }
}